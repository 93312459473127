import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { } from 'jquery';
declare var $;
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    filter,
} from 'rxjs/operators';

import { ConstantComponent } from '../../../constant.component';
import { headerService } from './header.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [headerService],
})
export class HeaderComponent implements OnInit {
    usernameLogged: string;
    subscriberNameLogged: string;
    SAMenu: boolean = true;
    mainterMenu: boolean = true;
    companyName: string;
    maintenanceData: any = [];
    maintenance: any = {};
    errorText: any;
    notificationDetails: any = [];
    notificationHeading: any = [];
    notificationBody: string;
    notificationId: number;
    notificationDate;
    notificationFor;
    notificationHead;
    notificationItemId: number;
    displayBtn = false;
    noOfNotification: number;
    sideShow = true;
    userRoleName: any;
    @Output() sidedat: EventEmitter<any> = new EventEmitter();
    constructor(
        private service: headerService,
        public router: Router,
        private modalService: NgbModal,
        private http: Http,
    ) {
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd && window.innerWidth <= 992) {
                this.toggleSidebar();
            }
        });
    }

    ngOnInit() {
        this.os_dropdown();
        this.usernameLogged = localStorage.getItem('usernameLogged');
        this.companyName = localStorage.getItem('CompanyName');
        this.userRoleName = localStorage.getItem('userRole');        
        this.subscriberNameLogged = localStorage.getItem(
            'subscriberName',
        );
        if (localStorage.getItem('SuperAdminRights')) {
            this.SAMenu = false;
            this.mainterMenu = true;
        } else if (localStorage.getItem('MaintainerRights')) {
            this.mainterMenu = false;
            this.SAMenu = true;
        } else {
            this.SAMenu = true;
            this.mainterMenu = true;
        }
    }
    notificationPopUp = function (notification, notificationId) {
        for (let i = 0; i < this.notificationDetails.length; i++) {
            if (this.notificationDetails[i].notificationId == notificationId) {
                this.notificationDate = this.notificationDetails[i].createdDate;
                this.notificationId = this.notificationDetails[i].notificationId;
                this.notificationBody = this.notificationDetails[i].notificationBody;
                this.notificationFor = this.notificationDetails[i].notificationFor;
                this.notificationHead = this.notificationDetails[i].notificationHeading;
                this.notificationItemId = this.notificationDetails[i].notificationItemId;
                this.checkApproveBtn();
                break;
            }
        }
        this.SuccessAlertHidden = true
        this.modalService.open(notification, { size: 'md' }).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    };
    checkApproveBtn() {
        if (this.notificationHead == "Consignment, Zero Quotation.") {
            this.displayBtn = true;
        } else if (this.notificationHead == "Vehicle Invoice Created") {
            this.displayBtn = true;
        } else if (this.notificationHead == "NEFT Transfer.") {
        } else {
            this.displayBtn = false;
            this.updateStatusOfNotification();
        }
    }
    updateStatusOfNotification() {
        this.service.get(ConstantComponent.url + '/NotificationAPI/Seen/' + this.notificationId).subscribe(data => {
        })
    }
    Approve = function (item, id) {
        if (item == "Consignment, Zero Quotation.") {
            this.service.get(ConstantComponent.url + '/ConsignmentAPI/ApproveZeroQuotation/' + this.notificationItemId).subscribe(data => {
            })
            this.updateStatusOfNotification();
            this.displayBtn = false;
        }
        if (item == "Vehicle Invoice Created") {
            this.service.get(ConstantComponent.url + '/VehicleInvoiceAPI/ApproveInvoice/' + this.notificationItemId).subscribe(data => {
            })
            this.updateStatusOfNotification();
            this.displayBtn = false;
        }
    }
    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('push-right');
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        localStorage.removeItem('isLoggedin');
        localStorage.removeItem('allRights');
        localStorage.removeItem('SuperAdminRights');
        localStorage.removeItem('subscriberIdLogged');
        localStorage.removeItem('usernameLogged');
        localStorage.removeItem('subscriberNameLogged');
        localStorage.removeItem('userIdLogged');
        localStorage.removeItem('userAllRights');
        localStorage.removeItem('tyreDashboardStatus');
        localStorage.removeItem('vehicleDashboardStatus');
        this.router.navigateByUrl('/login');


    }

    showHelplineModal = function (content) {
        this.modalService.open(content).result.then(
            result => {
                this.closeResult = `Closed with: ${result}`;
            },
            reason => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    };

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    os_dropdown = function () {
        $('.os-dropdown-trigger').on('mouseenter', function () {
            $(this).addClass('over');
        });
        $('.os-dropdown-trigger').on('mouseleave', function () {
            $(this).removeClass('over');
        });
    };

    convertTime = function (timeString: string) {
        var nums = timeString.split(':');
        var time;
        if (parseInt(nums[0]) > 12) {
            time = parseInt(nums[0]) - 12 + ':' + nums[1] + 'PM';
        } else if (parseInt(nums[0]) == 12) {
            time = parseInt(nums[0]) + ':' + nums[1] + 'PM';
        } else {
            time = parseInt(nums[0]) + ':' + nums[1] + 'AM';
        }
        return time;
    };

    setDate = function (inputDate) {
        if (inputDate == '') {
            var dtToday = new Date();
        } else {
            var dtToday = new Date(inputDate);
        }
        var month = (dtToday.getMonth() + 1).toString();
        var day = dtToday.getDate().toString();
        var year = dtToday.getFullYear();

        if (dtToday.getMonth() < 9) {
            month = '0' + month.toString();
        }
        if (dtToday.getDate() < 10) {
            day = '0' + day.toString();
        }
        var maxDate = year + '-' + month + '-' + day;
        return maxDate;
    };
    hideSideBar() {
        this.sideShow = !this.sideShow;
        ConstantComponent.sideBarOpen = this.sideShow;
        this.sidedat.emit(this.sideShow);
    }
}
