import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab-navigate',
  templateUrl: './tab-navigate.component.html',
  styleUrls: ['./tab-navigate.component.css']
})
export class TabNavigateComponent implements OnInit {

  @Input() type: any;
  @Input() prevDisabled: any;
  @Input() nextDisabled: any;

  @Output() selected = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  navigateTo(type: string) {
    this.selected.emit(type);
  }

}
