import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.css']
})
export class InputPasswordComponent implements OnInit {

  constructor() { }

  @Input() sharedVar: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() toolTip: string= ""

  @Output() sharedVarChange = new EventEmitter();


  ngOnInit() {
  }
  change(newValue) {
    this.sharedVarChange.emit(newValue);
  }

}
