import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';

declare var toastr;
declare var bootbox: any

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  alertbox: any = bootbox;
  print: any = 0;

  confirmBox(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Are you sure?');

    return of(confirmation);
  };

  alert(message) {
    bootbox.alert(message)
  }
  displayToastr(type?: string, message?: string, autoFade?: boolean) {

    var timeOut: any = "5000"

    if (autoFade == false) {
      timeOut = "2000"
    }
    toastr.options = {
      "closeButton": true,
      "timeOut": timeOut,
    }
    console.log(toastr)
    switch (type) {

      case "success":

        if (message == null) {
          message = "Data Saved Successfully";
        }
        toastr.success(message);

        break;

      case "error":

        if (message == null) {
          message = "Please Check Internet Connectivity";
        }
        toastr.error(message);

        break;

      case "warning":

        if (message == null) {
          message = "Warning!";
        }
        toastr.warning(message);

        break;

      case "clear":

        toastr.clear();

        break;

    }
    window.scrollTo(0,0)
  }

  exportFiltered(type?: string, tableId?: string,excelName?:string,columnsPrint?:any, href?){
    let exportData = []
    let lengthtable = document.getElementById(tableId).getElementsByTagName("tr").length;
    let table = document.getElementById(tableId);
    let rows =table.getElementsByTagName("tr");
    for (var i=1; i < lengthtable; i++){
      let cells =rows[i].getElementsByTagName("td");
      let exportstring = "{";
      for(var j=0;j<columnsPrint.columns.length;j++)
      {
        exportstring += "\""+columnsPrint.columns[j]+"\":\""+cells[j+2].innerText+"\","; 
      }
        exportstring = exportstring.substring(0, exportstring.length - 1)+ "}";  
        let obj = JSON.parse(exportstring);
        console.log(obj)
        exportData.push(obj)
        console.log(i,exportstring)
    }
        // this.exportService.export(exportData, excelName, true, type, href)
  }

}
