import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.css']
})
export class InputTextareaComponent implements OnInit {

  constructor() { }

  @Input() sharedVar: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() maxLength: number;
  @Input() isDisabled: boolean;
  @Input() toolTip: string= ""
  @Input() showErrorMessage: boolean;

  lengthErrorMessage: string;
  lengthValid: boolean = true;

  @Output() sharedVarChange = new EventEmitter();

  @ViewChild('modelRef') modelRef: NgModel;

  invalidMsg: string
  ngOnInit() {
    this.lengthErrorMessage = `Maximum Allowed Length is ${this.maxLength}`
  }

  change(newValue) {
    if (newValue != null) {
      if (newValue.length > 0) {

        if (newValue != null) {
          newValue = newValue.trim();
        }
        if (!newValue.replace(/\s/g, '').length) {
          // string only contained whitespace (ie. spaces, tabs or line breaks)
          this.invalidMsg = `Please enter any character`
          this.modelRef.control.setErrors({ 'invalid': true })
        } else {
          if (this.maxLength != undefined) {
            if (newValue.length == this.maxLength) {
              //this.lengthErrorMessage = `Maximum Allowed Length is ${this.maxLength}`
              //this.modelRef.control.setErrors({ 'invalid': true })
            }
            else if(newValue.length > this.maxLength){
              this.modelRef.control.setErrors({'invalid' : true})
              this.invalidMsg = `Maximum Allowed Length is ${this.maxLength}`
              this.lengthValid = true
              //this.lengthErrorMessage = undefined
            }
            else {
              this.lengthValid = true
              //this.lengthErrorMessage = undefined
              this.sharedVarChange.emit(newValue);
            }
          } else {
            this.invalidMsg = null
            this.modelRef.control.setErrors(null)
            this.sharedVarChange.emit(newValue);

          }
        }
      } else {
        if (this.isMandatory == true && this.showErrorMessage != false) {
          this.invalidMsg = `Required`
          this.modelRef.control.setErrors({ 'invalid': true })
        }
        else if(this.isMandatory == true && this.showErrorMessage == false){
          this.invalidMsg = ""
          this.modelRef.control.setErrors({ 'invalid': true })
        } else {
          this.invalidMsg = null
          this.modelRef.control.setErrors(null)
        }
      }
      this.sharedVarChange.emit(newValue);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sharedVar']) {
      if (this.sharedVar != undefined && this.sharedVar != '') {
        this.sharedVar = this.sharedVar[0].toUpperCase() + this.sharedVar.slice(1)
      }
    }
  }

  keyPress(str){
    console.log(this.lengthValid)
    console.log(str.length)
    this.lengthValid = (this. maxLength == undefined || str.length  < this.maxLength) ? true : false
    console.log(this.lengthValid)
    if(this.lengthValid == false){
      setTimeout(()=>{
        this.lengthValid = true
      },2000)
      return false
    }
    
  }

}
