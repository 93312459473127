import { Component } from '@angular/core';

@Component({
    selector: 'app-Constant',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class ConstantComponent {
    public static url: string;
    public static port: string;
    public static reportUrl: string;
    public static nodeUrl: string;
    public static sideBarOpen = true;
    public showProgress: boolean;
    public currUrl: string;

    public static userAllRights = JSON.parse(
        localStorage.getItem('userAllRights'),
    );
    public static userRights: any = localStorage.getItem('userRights');
    public static userName: any = localStorage.getItem('usernameLogged');
    public static subscriberGstNo: any = localStorage.getItem('SubscriberGstNo');
    public static subscriberIdLogged: Number = Number(localStorage.getItem('SubscriberMasterId'));
    public static subscriberNameLogged: String = localStorage.getItem('subscriberName');
    public static userIdLogged = localStorage.getItem('userIdLogged');
    public static financialYearIdLogged = Number(
        localStorage.getItem('financialYearIdLogged'),
    );
    public static superAdminRights: any = localStorage.getItem(
        'SuperAdminRights',
    );
    public static maintainerRights: any = localStorage.getItem(
        'MaintainerRights',
    );
}
