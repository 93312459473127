import { Component, OnInit } from '@angular/core';
import { ConstantComponent } from '../../../constant.component';
import { } from 'jquery';
import { Router } from '@angular/router';
declare var $;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'
    ],
})
export class SidebarComponent {
    userMenuRight = []
    isActive = false;
    showMenu = '';
    userRights: any;
    userAllRights: any;
    VehicleMenuHidden = true
    MaterialMenuHidden = true
    HireslipMenuHidden = true
    PlacementMenuHidden = true
    CreateMRQuotationMenuHidden = true
    BillDispatchMenuHidden = true
    PlaceMenuHidden = true
    OtherPartyMenuHidden = true
    OtherPartyAddMenuHidden = true
    ConsigneeMenuHidden = true
    ConsignerMenuHidden = true
    AccountGroupMenuHidden = true
    ClientMenuHidden = true
    VendorMenuHidden = true
    ConsignmentMenuHidden = true
    PartyMasterMenuHidden = true
    OperationsMenuHidden = true
    TransferMenuHidden = true
    LARMenuHidden = true
    ViewVoucherMenuHidden = true
    ConHSPlaceMenuHidden = true
    OtherMastersMenuHidden = true
    notYetMenuHidden = true
    UserMenuHidden = true
    RoleMenuHidden = true
    RoleAddMenuHidden = true
    RouteMenuHidden = true
    UsernRoleMenuHidden = true
    BalancePaymentMenuHidden = true
    notSAMenu = true
    AssetMenuHidden = true
    SAMenu = false
    PlacementAccountsMenuHidden = true
    HireSlipAccountsMenuHidden = true
    CostCenterMenuHidden = true
    ExceptionTripMenuHidden = true
    AccountingMenuHidden = true
    PlacementApproveMenuHidden = true
    ViewInvoiceMenuHidden = true
    ViewCreditNoteMenuHidden = true
    ConsignmentInvoiceMenuHidden = true
    CreateMRMenuHidden = true
    CreateLedgerMenuHidden = true
    CreateDebitNote = true
    CreateBillingMenuHidden = true
    AddOnAccountMRMenuHidden = true
    InvoiceMenuHidden = true
    OfficeMenuHidden = true
    ZoneMenuHidden = true
    GodownMenuHidden = true
    AreaMenuHidden = true
    AdvancePaymentMenuHidden = true
    DisbursementMenuHidden = true
    LedgerHeadMenuHidden = true
    CostCategoryMenuHidden = true
    BookMenuHidden = true
    CalculationMasterMenuHidden = true
    RateMasterMenuHidden = true
    VerticalMenuHidden = true
    RegionMenuHidden = true
    VehicleTypeMenuHidden = true
    StationaryMasterMenuHidden = true
    EmailUserConfigMenuHidden = true
    SanctionMenuHidden = true
    ExpenseTypeMenuHidden = true
    SendEmailMenuHidden = true
    ConsignmentTrackingMenuHidden: boolean = true
    EtollMenuHidden: boolean = true
    vehicleBillingMenuHidden: boolean = true
    TripSettlementMenuHidden: boolean = true
    IncidentalExpenseIncideMenuHidden: boolean = true
    ExpenseMasterMenuHidden: boolean = true
    FuelRateMasterMenuHidden: boolean = true
    FuelRateBudgetingMenuHidden: boolean = true
    TdsMasterMenuHidden: boolean = true
    BankRecoMenuHidden: boolean = true
    VehicleMasterMenuHidden: boolean = true
    VehicleModelMenuHidden: boolean = true
    VehicleDueTransactionMenuHidden: boolean = true
    ProductLogMenuHidden: boolean = true
    TyreStockMenuHidden: boolean = true
    IssueTyreMenuHidden: boolean = true
    InOrOutWardMenuHidden: boolean = true
    TyreInspectionMenuHidden: boolean = true
    ExcelUploadKMMenuHidden: boolean = true
    NewRemouldMenuHidden: boolean = true
    ChassisTyreMenuHidden: boolean = true
    RemouldTyreMenuHidden: boolean = true
    AgainstClaimGrnMenuHidden: boolean = true
    SendToRemouldMenuHidden: boolean = true
    SendToClaimMenuHidden: boolean = true
    ResaleToBuyerMenuHidden: boolean = true
    TheftMenuHidden: boolean = true
    ScrapMenuHidden: boolean = true
    DueMasterMenuHidden: boolean = true
    DriverMasterMenuHidden: boolean = true
    TripLogMenuHidden: boolean = true
    FleetVendorMasterMenuHidden: boolean = true
    OperationalReportMenuHidden: boolean = true
    AccountingReportTripLogMenuHidden: boolean = true
    TyreReportTripLogMenuHidden: boolean = true
    AdvanceTripBudgetingMenuHidden: boolean = true
    OperationMainMenuHidden: boolean = false
    FinanceMainMenuHidden: boolean = false
    UserRoleMainMenuHidden: boolean = false
    mainterMenu: boolean = true
    isTyreDashboard: boolean = false
    isVehicleDashboard: boolean = false
    isInvetoryDashboard: boolean = false
    CashFlowReportMenuHidden: boolean = true
    FinancialReportMenuHidden: boolean = true
    VoucherMenuHidden: boolean = true
    simpleQuotationHidden: boolean = false
    vehicleQuotationHidden: boolean = false
    productQuotationHidden: boolean = false
    ftlQuotationHidden: boolean = true
    quotationHidden: boolean = true
    podHidden: boolean = true

    SubscriberLogo: string;
    CashTransferMenuHidden: boolean = true
    constructor(public router: Router) { }
    ngOnInit() {
        this.SubscriberLogo = localStorage.getItem('SubscriberLogoImage');

        this.addBgClass()
        this.os_init_sub_menus()
        this.os_init_mobile_menus()
        if (localStorage.getItem('SuperAdminRights')) {

            this.notSAMenu = true
            this.SAMenu = false
            this.RouteMenuHidden = true
            this.mainterMenu = true
            this.isTyreDashboard = false
            this.isVehicleDashboard = false
            this.isInvetoryDashboard = false
        } else if (localStorage.getItem('MaintainerRights')) {
            this.notSAMenu = true
            this.SAMenu = true
            this.RouteMenuHidden = true
            this.mainterMenu = false
            this.isTyreDashboard = false
            this.isVehicleDashboard = false
            this.isInvetoryDashboard = false
        } else {
            this.notSAMenu = false
            this.SAMenu = true
            this.mainterMenu = true
            this.userRights = ConstantComponent.userRights
            this.userAllRights = ConstantComponent.userAllRights
            if (localStorage.getItem('tyreDashboardStatus') == 'true') {
                this.isTyreDashboard = true
            } else {
                this.isTyreDashboard = false
            }
            if (localStorage.getItem('vehicleDashboardStatus') == 'true') {
                this.isVehicleDashboard = true
            } else {
                this.isVehicleDashboard = false
            }
            if (localStorage.getItem('inventoryDashboardStatus') == 'true') {
                this.isInvetoryDashboard = true
            } else {
                this.isInvetoryDashboard = false
            }
            for (let i = 0; i < this.userAllRights.length; i++) {
                if (this.userAllRights[i].formId == 1) {
                    this.VendorMenuHidden = false
                }
                if (this.userAllRights[i].formId == 6) {
                    this.PlaceMenuHidden = false
                }
                if (this.userAllRights[i].formId == 7) {
                    this.PlacementMenuHidden = false
                }
                if (this.userAllRights[i].formId == 3) {
                    this.ConsignerMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2) {
                    this.ClientMenuHidden = false
                }
                if (this.userAllRights[i].formId == 4) {
                    this.ConsigneeMenuHidden = false
                }
                if (this.userAllRights[i].formId == 5) {
                    this.OtherPartyMenuHidden = false
                }
                if (this.userAllRights[i].formId == 5 && this.userAllRights[i].canEdit == true) {
                    this.OtherPartyAddMenuHidden = false
                }
                if ((this.userAllRights[i].formId == 1) || (this.userAllRights[i].formId == 5) || (this.userAllRights[i].formId == 2) || (this.userAllRights[i].formId == 3) || (this.userAllRights[i].formId == 4)) {
                    this.PartyMasterMenuHidden = false
                }

                if ((this.userAllRights[i].formId == 1) || (this.userAllRights[i].formId == 5) || (this.userAllRights[i].formId == 2) || (this.userAllRights[i].formId == 3) || (this.userAllRights[i].formId == 4)) {
                    this.OperationsMenuHidden = false
                }


                if ((this.userAllRights[i].formId == 1) || (this.userAllRights[i].formId == 5) || (this.userAllRights[i].formId == 2) || (this.userAllRights[i].formId == 3) || (this.userAllRights[i].formId == 4)) {
                    this.TransferMenuHidden = false
                }

                if ((this.userAllRights[i].formId == 1) || (this.userAllRights[i].formId == 5) || (this.userAllRights[i].formId == 2) || (this.userAllRights[i].formId == 3) || (this.userAllRights[i].formId == 4)) {
                    this.LARMenuHidden = false
                }


                if ((this.userAllRights[i].formId == 13) || (this.userAllRights[i].formId == 6)) {
                    this.OtherMastersMenuHidden = false
                }
                if (this.userAllRights[i].formId == 7 && this.userAllRights[i].canApprove == true) {
                    this.PlacementApproveMenuHidden = false
                }
                if (this.userAllRights[i].formId == 8) {
                    this.HireslipMenuHidden = false
                }
                if (this.userAllRights[i].formId == 9) {
                    this.VehicleMenuHidden = false
                }
                if (this.userAllRights[i].formId == 22) {
                    this.MaterialMenuHidden = false
                }
                if (this.userAllRights[i].formId == 10) {
                    this.ConsignmentMenuHidden = false
                }
                if (this.userAllRights[i].formId == 11) {
                    this.UserMenuHidden = false
                }
                if (this.userAllRights[i].formId == 12) {
                    this.RoleMenuHidden = false
                }
                if (this.userAllRights[i].formId == 12 && this.userAllRights[i].canEdit == true) {
                    this.RoleAddMenuHidden = false
                }
                if (this.userAllRights[i].formId == 13) {
                    this.RouteMenuHidden = false
                }
                if (this.userAllRights[i].formId == 14) {
                    this.PlacementAccountsMenuHidden = false
                }
                if (this.userAllRights[i].formId == 9 || this.userAllRights[i].formId == 22) {
                    this.AssetMenuHidden = false
                }
                if (this.userAllRights[i].formId == 15) {
                    this.HireSlipAccountsMenuHidden = false
                }
                if (this.userAllRights[i].formId == 7 || this.userAllRights[i].formId == 8 || this.userAllRights[i].formId == 10) {
                    this.ConHSPlaceMenuHidden = false
                }
                if (this.userAllRights[i].formId == 14 || this.userAllRights[i].formId == 15) {
                    this.AccountingMenuHidden = false
                }
                if (this.userAllRights[i].formId == 17) {
                    this.OfficeMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2087) {
                    this.ZoneMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2086) {
                    this.AreaMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2088) {
                    this.GodownMenuHidden = false
                }
                if (this.userAllRights[i].formId == 27 || this.userAllRights[i].formId == 28 || this.userAllRights[i].formId == 29) {
                    this.DisbursementMenuHidden = false
                }
                if (this.userAllRights[i].formId == 21) {
                    this.AccountGroupMenuHidden = false
                }
                if (this.userAllRights[i].formId == 23) {
                    this.ViewInvoiceMenuHidden = false
                }
                if (this.userAllRights[i].formId == 37) {
                    this.ViewCreditNoteMenuHidden = false
                }
                if (this.userAllRights[i].formId == 24 && this.userAllRights[i].canEdit == true) {
                    this.ConsignmentInvoiceMenuHidden = false
                }
                if (this.userAllRights[i].formId == 25) {
                    this.CreateMRMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2095) {
                    this.CreateMRQuotationMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2098) {
                    this.BillDispatchMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2094) {
                    this.CreateBillingMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2103) {
                    this.vehicleBillingMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2092) {
                    this.CreateLedgerMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2093) {
                    this.CreateDebitNote = false
                }
                if (this.userAllRights[i].formId == 26) {
                    this.AddOnAccountMRMenuHidden = false
                }
                if (this.userAllRights[i].formId == 25 || this.userAllRights[i].formId == 2092 || this.userAllRights[i].formId == 2095 || this.userAllRights[i].formId == 2093 || this.userAllRights[i].formId == 2094) {
                    this.InvoiceMenuHidden = false
                }
                if (this.userAllRights[i].formId == 29) {
                    this.ViewVoucherMenuHidden = false
                }
                if (this.userAllRights[i].formId == 27) {
                    this.AdvancePaymentMenuHidden = false
                }
                if (this.userAllRights[i].formId == 28) {
                    this.BalancePaymentMenuHidden = false
                }
                if (this.userAllRights[i].formId == 20) {
                    this.LedgerHeadMenuHidden = false
                }
                if (this.userAllRights[i].formId == 30) {
                    this.CostCenterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 31) {
                    this.CostCategoryMenuHidden = false
                }
                if (this.userAllRights[i].formId == 39) {
                    this.CalculationMasterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 38) {
                    this.RateMasterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 33) {
                    this.VerticalMenuHidden = false
                }
                if (this.userAllRights[i].formId == 32) {
                    this.RegionMenuHidden = false
                }
                if (this.userAllRights[i].formId == 35) {
                    this.VehicleTypeMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2090) {
                    this.BookMenuHidden = false
                }
                if (this.userAllRights[i].formId == 34) {
                    this.StationaryMasterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2096) {
                    this.ExpenseTypeMenuHidden = false
                }
                if (this.userAllRights[i].formId == 3102) {
                    this.SendEmailMenuHidden = false
                }
                if (this.userAllRights[i].formId == 40) {
                    this.EmailUserConfigMenuHidden = false
                }
                if (this.userAllRights[i].formId == 41) {
                    this.TripLogMenuHidden = false
                }
                if (this.userAllRights[i].formId == 42) {
                    this.FleetVendorMasterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 43) {
                    this.SanctionMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1045) {
                    this.EtollMenuHidden = false
                }

                if (this.userAllRights[i].formId == 44) {
                    this.DueMasterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1046) {
                    this.TripSettlementMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1047) {
                    this.IncidentalExpenseIncideMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1050) {
                    this.FuelRateMasterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1079) {
                    this.FuelRateBudgetingMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1051) {
                    this.TdsMasterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1052) {
                    this.BankRecoMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1054) {
                    this.VehicleModelMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1055) {
                    this.VehicleDueTransactionMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1056) {
                    this.ProductLogMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1057) {
                    this.TyreStockMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1058) {
                    this.IssueTyreMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1059) {
                    this.InOrOutWardMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1060) {
                    this.TyreInspectionMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1061) {
                    this.ExcelUploadKMMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1062) {
                    this.NewRemouldMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1063) {
                    this.ChassisTyreMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1064) {
                    this.RemouldTyreMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1065) {
                    this.AgainstClaimGrnMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1066) {
                    this.SendToRemouldMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1067) {
                    this.SendToClaimMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1068) {
                    this.ResaleToBuyerMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1069) {
                    this.TheftMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1070) {
                    this.ScrapMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1077) {
                    this.DriverMasterMenuHidden = false
                }
                if (this.userAllRights[i].formId == 3105) {
                    this.FinancialReportMenuHidden = false
                }
                if (this.userAllRights[i].formId == 3104) {
                    this.OperationalReportMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2097) {
                    this.VoucherMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1075) {
                    this.AccountingReportTripLogMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1076) {
                    this.TyreReportTripLogMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1080) {
                    this.AdvanceTripBudgetingMenuHidden = false
                }
                if (this.userAllRights[i].formId == 1078) {
                    this.ExceptionTripMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2100) {
                    this.ConsignmentTrackingMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2099) {
                    this.CashTransferMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2101) {
                    this.CashFlowReportMenuHidden = false
                }
                if (this.userAllRights[i].formId == 2082) {
                    this.simpleQuotationHidden = false
                }
                if (this.userAllRights[i].formId == 2083) {
                    this.vehicleQuotationHidden = false
                }
                if (this.userAllRights[i].formId == 2084) {
                    this.productQuotationHidden = false
                }
                if (this.userAllRights[i].formId == 2085) {
                    this.ftlQuotationHidden = false
                }
                if (this.userAllRights[i].formId == 2104) {
                    this.podHidden = false
                }
                if (this.userAllRights[i].formId == 2082 || this.userAllRights[i].formId == 2083 || this.userAllRights[i].formId == 2084 || this.userAllRights[i].formId == 2085) {
                    this.quotationHidden = false
                }
            }
            if (this.UserMenuHidden == true && this.RoleAddMenuHidden == true && this.RoleMenuHidden == true) {
                this.UserRoleMainMenuHidden = true
            }
        }
    }
    eventCalled() {
        this.isActive = !this.isActive;
    }
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    addBgClass = function () {
        $('body').removeClass('bg-color-class');
    }



    os_init_sub_menus = function () {

        var menu_timer;
        $('.menu-activated-on-hover').on('mouseenter', 'ul.main-menu > li.has-sub-menu', function () {
            var $elem = $(this);
            clearTimeout(menu_timer);
            $elem.closest('ul').addClass('has-active').find('> li').removeClass('active');
            $elem.addClass('active');
        });

        $('.menu-activated-on-hover').on('mouseleave', 'ul.main-menu > li.has-sub-menu', function () {
            var $elem = $(this);
            menu_timer = setTimeout(function () {
                $elem.removeClass('active').closest('ul').removeClass('has-active');
            }, 30);
        });


        $('.menu-activated-on-click').on('click', 'li.has-sub-menu > a', function (event) {
            var $elem = $(this).closest('li');
            if ($elem.hasClass('active')) {
                $elem.removeClass('active');
            } else {
                $elem.closest('ul').find('li.active').removeClass('active');
                $elem.addClass('active');
            }
            return false;
        });
    }

    os_init_mobile_menus = function () {
        $('.mobile-menu-trigger').on('click', function () {
            $('.menu-mobile .menu-and-user').slideToggle(200, 'swing');
            return false;
        });
    }

    menuClose = function () {
        $('.menu-mobile .menu-and-user').slideToggle(200, 'swing');
        return false;
    }

    closeMenu(event: Event) {
        const elem = (event.target as HTMLElement).closest('li.has-sub-menu');
        if (elem) {
            elem.classList.remove('active');
        }
    }

    showHideTyreDashboard = function (eve) {
        if (eve == "tyre") {
            localStorage.removeItem('tyreDashboardStatus')
            localStorage.removeItem('vehicleDashboardStatus')
            localStorage.removeItem('inventoryDashboardStatus')
            this.isVehicleDashboard = false
            this.isTyreDashboard = !this.isTyreDashboard
            this.isInventoryDashboard = false;
            if (this.isTyreDashboard == true) {
                localStorage.setItem('tyreDashboardStatus', 'true');
                this.router.navigate(['./dashboard'], { queryParams: { type: "Tyre" } });
            } else {
                localStorage.setItem('tyreDashboardStatus', 'false');
                this.router.navigate(['./dashboard'], { queryParams: { type: "Operation" } });
            }
        }
        if (eve == "operational") {
            localStorage.removeItem('tyreDashboardStatus')
            this.isTyreDashboard = false

            localStorage.removeItem('vehicleDashboardStatus')
            this.isVehicleDashboard = false
            this.isInventoryDashboard = false;
            this.router.navigate(['./dashboard'], { queryParams: { type: "Operation" } });

            localStorage.removeItem('inventoryDashboardStatus')

        }
        if (eve == "vehicle") {
            localStorage.removeItem('vehicleDashboardStatus')
            localStorage.removeItem('tyreDashboardStatus')
            localStorage.removeItem('inventoryDashboardStatus')
            this.isTyreDashboard = false
            this.isVehicleDashboard = !this.isVehicleDashboard
            this.isInventoryDashboard = false;
            if (this.isVehicleDashboard == true) {
                localStorage.setItem('vehicleDashboardStatus', 'true');
                this.router.navigate(['./dashboard'], { queryParams: { type: "Vehicle" } });
            } else {
                localStorage.setItem('vehicleDashboardStatus', 'false');
                this.router.navigate(['./dashboard'], { queryParams: { type: "Operation" } });
            }
        }


    }
    showHideInventoryDashboard = function () {
        localStorage.removeItem('vehicleDashboardStatus')
        localStorage.removeItem('tyreDashboardStatus')
        localStorage.removeItem('inventoryDashboardStatus')
        this.isTyreDashboard = false
        this.isVehicleDashboard = false
        this.isInventoryDashboard = true;
        if (this.isInventoryDashboard == true) {
            localStorage.setItem('inventoryDashboardStatus', 'true');
            this.router.navigate(['./dashboard'], { queryParams: { type: "Inventory" } });
        } else {
            localStorage.setItem('inventoryDashboardStatus', 'false');
            this.router.navigate(['./dashboard'], { queryParams: { type: "Operation" } });
        }
    }


    mouseEnter(div: string) {
        $('.billing-menu-check').show();

    }

    mouseLeave(div: string) {
        $('.billing-menu-check').hide();
    }
}
