import { LoaderService } from './../../../GenericService/loader.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-component-header',
    templateUrl: './component-header.component.html',
    styleUrls: ['./component-header.component.css'],
})
export class ComponentHeaderComponent implements OnInit {
    @Input() valuesFromParent: any;
    @Output() exportToExcel = new EventEmitter();
    @Output() printData = new EventEmitter();
    constructor(private loaderService: LoaderService) {}

    ngOnInit() {}
    stopLoader() {
        this.loaderService.display(false);
    }
    print() {
        this.printData.emit();
    }
    export() {
        this.exportToExcel.emit();
    }
}
