import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.css']
})
export class InputRadioComponent implements OnInit {

  constructor() { }

  @Input() itemList: any
  @Input() sharedVar: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() toolTip: string= ""
  @Input() isDisabled: boolean;

  @Output() sharedVarChange = new EventEmitter();


  ngOnInit() {

    if(this.itemList == null) {
      this.itemList = [
        {
          id: true,
          text: "Yes"
        },
        {
          id: false,
          text: "No"
        }
      ]
    }
  }

  selectRadioOption(newValue: any) {
    this.sharedVar = newValue;
    this.sharedVarChange.emit(newValue);
  }


}
