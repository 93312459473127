import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonUiModuleComponent } from './common-ui-module.component';
import { ComponentHeaderComponent } from './component-header/component-header.component';
import { RouterModule } from '@angular/router';
import { TypeAheadComponent } from './type-ahead/type-ahead.component';
import { FormsModule } from '@angular/forms';
import { InputTextComponent } from './input-text/input-text.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { InputDateComponent } from './input-date/input-date.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputRadioComponent } from './input-radio/input-radio.component';
import { TabNavigateComponent } from './tab-navigate/tab-navigate.component';
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { ActionDropdownComponent } from './action-dropdown/action-dropdown.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputTextDuplicateCheckComponent } from './input-text-duplicateCheck/input-text-duplicateCheck.component';
import { InputEmailComponent } from './input-email/input-email.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import {
    NgbDatepickerConfig,
    NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from './ngb-date-fr-parser-formatter';
import { InputMultiSelectComponent } from './input-multi-select/input-multi-select.component';
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { InputDateTimeComponent } from './input-date-time/input-date-time.component';
import {
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OWL_DATE_TIME_FORMATS,
    OWL_DATE_TIME_LOCALE,
} from 'ng-pick-datetime';
import { ChildFieldValidationDirective } from '../Directives/child-field-validation.directive';
import { CustomMaxDirective } from '../Directives/custom-max-validator.directive';
import { CustomMinDirective } from '../Directives/custom-min-validator.directive';

export const MY_NATIVE_FORMATS = {
    fullPickerInput: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    },
    datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
    timePickerInput: { hour: 'numeric', minute: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TooltipModule,
        NgbModule,
        AngularDateTimePickerModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    declarations: [
        CommonUiModuleComponent,
        ComponentHeaderComponent,
        TypeAheadComponent,
        InputTextComponent,
        ChildFieldValidationDirective,
        CustomMaxDirective,
        CustomMinDirective,
        InputNumberComponent,
        InputSelectComponent,
        InputDateComponent,
        InputRadioComponent,
        TabNavigateComponent,
        InputTextareaComponent,
        ActionDropdownComponent,
        InputPasswordComponent,
        InputTextDuplicateCheckComponent,
        InputEmailComponent,
        InputMultiSelectComponent,
        InputDateTimeComponent,
        //InputUploadComponent,
    ],

    exports: [
        ComponentHeaderComponent,
        TypeAheadComponent,
        CommonUiModuleComponent,
        InputTextComponent,
        ChildFieldValidationDirective,
        CustomMaxDirective,
        CustomMinDirective,
        InputNumberComponent,
        InputSelectComponent,
        InputDateComponent,
        InputRadioComponent,
        TabNavigateComponent,
        InputTextareaComponent,
        //InputUploadComponent,
        InputEmailComponent,
        ActionDropdownComponent,
        InputPasswordComponent,
        InputTextDuplicateCheckComponent,
        InputDateTimeComponent,
    ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' },
    ],
})
export class CommonUiModuleModule {}
