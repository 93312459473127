import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-input-date-time',
  templateUrl: './input-date-time.component.html',
  styleUrls: ['./input-date-time.component.css']
})
export class InputDateTimeComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) { }

  @Input() sharedVar: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() max: any;
  @Input() min: any;
  @Input() isFocus: boolean
  @Input() detectInvalid: boolean;
  @Input() isDisabled: boolean;
  @Input() toolTip: string= ""

  @Output() sharedVarChange = new EventEmitter();
  @ViewChild('childRef') childRef: NgModel;

  ngOnInit() {

  }
  change(newValue) {
    if (this.childRef.valid) {
        this.sharedVarChange.emit(newValue);
    }
  }

  
}
