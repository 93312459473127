import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css']
})
export class InputNumberComponent implements OnInit {

  constructor() { }

  @Input() sharedVar: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() min: number;
  @Input() max: number;
  @Input() isDisabled: boolean;
  @Input() uptoDecimal: number;
  @Input() xmin: number;
  @Input() step: number;
  @Input() maxLength: number;
  lengthValid: boolean = true;
  @Input() toolTip: string= ""
  @Input() showErrorMessage: boolean;
  

  @Output() sharedVarChange = new EventEmitter();


  ngOnInit() {
  }
  change(newValue) {
    this.sharedVarChange.emit(newValue);
    if (newValue != null) {
      if (this.maxLength != undefined) {
        if (newValue.length >= this.maxLength) {
          this.lengthValid = false
        } else {
          this.lengthValid = true
        }
      }
    }
  }
  restrictPlace() {
    if (this.sharedVar != null && this.step != null) {
      this.sharedVar = String(this.sharedVar)
      if(this.sharedVar.indexOf('.') >= 0){
        this.sharedVar = this.sharedVar.substring(0, this.sharedVar.indexOf('.') + this.step + 1);
      }
      this.sharedVar = Number(this.sharedVar)
    }
    this.sharedVarChange.emit(this.sharedVar);
  }
 

// Listen for input event on numInput.
  checkNegative(e) {

    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58) 
      || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 190 || e.keyCode == 110 || e.keyCode == 17 || e.keyCode == 86 || e.keyCode == 91 || e.keyCode == 37 || e.keyCode == 39)) {
        return false;
    }
  }

  keyPress(str){
    console.log(this.lengthValid)
    console.log(str.length)
    this.lengthValid = (this.maxLength == undefined || str.length < this.maxLength) ? true : false
    console.log(this.lengthValid)
    if(this.lengthValid == false){
      setTimeout(()=>{
        this.lengthValid = true
      },2000)
      return false
    }
    
  }
  
}
