import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Http, HttpModule, RequestOptions } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbProgressbarModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { ConstantComponent } from './constant.component';
import { ValidationService } from './GenericService/validation.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { GoogleChartsModule } from 'angular-google-charts';
import { AppHttpInterceptor } from './Interceptor/httpInterceptor';
import { CommonUiModuleModule } from './Common-UI-Module/common-ui-module/common-ui-module.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { NgxSpinnerModule } from "ngx-spinner";
import { SafePipe } from './safe.pipe';
import { DataService } from './service/data.service';
@NgModule({
    declarations: [AppComponent, ConstantComponent, ValidationService, SafePipe],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpModule,
        HttpClientModule,
        AppRoutingModule,
        AutocompleteLibModule,
        CommonUiModuleModule,
        GoogleChartsModule,
        NgbModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),
        MomentModule,
        NgxSpinnerModule,
        NgbProgressbarModule.forRoot(),
        NgbTypeaheadModule
        // ToastModule.forRoot()
    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
