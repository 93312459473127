import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';

const routes: Routes = [
    {
        path: '',
        loadChildren: './Master/master.module#MasterModule',
        canActivate: [AuthGuard]
    },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'reset/:id', loadChildren: './ResetPassword/resetpassword.module#ResetPasswordModule' },
    { path: 'register', loadChildren: './register/register.module#RegisterModule' },
    { path: 'forgotpassword', loadChildren: './forgotPassword/forgotpassword.module#ForgotPasswordModule' },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
