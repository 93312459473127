import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { NgModel } from '@angular/forms';


@Component({
  selector: 'app-input-text-duplicateCheck',
  templateUrl: './input-text-duplicateCheck.component.html',
  styleUrls: ['./input-text-duplicateCheck.component.css']
})
export class InputTextDuplicateCheckComponent implements OnInit, AfterViewInit {

  constructor() { }

  @Input() sharedVar: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() pattern: any
  @Input() invalidMsg: string
  @Input() isDisabled: boolean;
  @Input() patternType: string // using patternType will override pattern and invalidMsg

  @Input() isDuplicate: boolean
  @Input() detectChange: boolean
  @Input() toolTip: string = ""

  @Input() maxLength: number
  @Input() isFocus: boolean
  @Input() isCapital: boolean;
  lengthErrorMessage: string;
  lengthValid : boolean = true;
  oldMessage: string;

  @Output() sharedVarChange = new EventEmitter();

  @ViewChild('currentModel') currentModel: NgModel;
  patternMessage: any = [
    {
      type: "mobile",
      message: "Input Should be 10 Digit Number"
    },
    {
      type: "email",
      message: "Input Format Should be abc@xyz.eg"
    },
    {
      type: "alphabet",
      message: "Input Should be Only Alphabets"
    },
    {
      type: "gstno",
      message: "Invalid GST No"
    }
  ]
  ngOnInit() {
    this.lengthErrorMessage = `Maximum Allowed Length is ${this.maxLength}`
    if (this.patternType != undefined) {
      switch (this.patternType) {
        case "alphabet":
          this.pattern = '^[a-zA-Z]+([ ][a-zA-Z]+)*$';
          this.invalidMsg = "Input Should be Only Alphabets";
          break;
        case 'alphaNumeric':
          this.pattern = '^[a-zA-Z0-9]+([ ][a-zA-Z0-9]+)*$';
          this.invalidMsg = "Input Should be Only Alphabets or Numbers";
          break;
        case "email":
          this.pattern = '[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
          this.invalidMsg = "Input Format Should be abc@xyz.eg"
          break;
        case "mobile":
          this.pattern = '^[0-9]{10}$';
          this.invalidMsg = "Input Should be 10 Digit Number";
          break;

        case "numeric":
          this.pattern = '^[0-9]*$';
          this.invalidMsg = "Input Should be Number"
          break;

        case "pincode":
          this.pattern = '^[0-9]{6}$';
          this.invalidMsg = "Input Should be 6 Digit Number";
          break;

        case "email":
          this.pattern = '[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
          this.invalidMsg = "email invalid"
          break;

        case "gstno":
          this.pattern = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[0-9A-Z]$';
          this.invalidMsg = "GST No invalid"
          break;
      }
    }
  }

  ngAfterViewInit(){
    console.log(this.isFocus)
    if (this.isFocus == true) {
      document.getElementById(this.fieldId).focus();
    }
  }

  change(newValue) {
    if (newValue != null) {
      var stringLength: number = newValue.length
      if (!newValue.replace(/\s/g, '').length) {
        if (stringLength > 1) {
          this.invalidMsg = `Please enter any character`
          this.currentModel.control.setErrors({ 'invalid': true })
        }
      }
      if (newValue != null) {
        newValue = newValue.trim();
      }

      if (newValue == "") {
        if (this.isMandatory == true) {
          if (stringLength < 1) {
            this.invalidMsg = "Required"
          } else {
            this.invalidMsg = `Please enter any character`
          }
          this.oldMessage = this.invalidMsg
          this.currentModel.control.setErrors({ 'invalid': true })
        } else {
          this.setDefaultPatternMsg()
        }
      } else {

        if (this.maxLength != undefined) {
          if (newValue.length == this.maxLength) {
            // this.lengthValid = false
            // this.lengthErrorMessage = `Maximum Allowed Length is ${this.maxLength}`
             //this.currentModel.control.setErrors({ 'invalid': true })
           }
           else if(newValue.length > this.maxLength){
             this.invalidMsg = `Maximum Allowed Length is ${this.maxLength}`
             this.oldMessage = this.invalidMsg
             this.currentModel.control.setErrors({'invalid':true})
             this.lengthValid = true
             this.lengthErrorMessage = undefined
           }
           else {
             this.lengthValid = true
             this.lengthErrorMessage = undefined
             this.setDefaultPatternMsg()
           }
        }
      }
      this.sharedVar = newValue;
      this.sharedVarChange.emit(newValue);
    }
  }
  setDefaultPatternMsg() {
    if (this.patternType != null) {
      var invalidData = this.patternMessage.filter(x => x.type == this.patternType)
      console.log(invalidData)
      if (invalidData.length > 0) {
        //this.invalidMsg = invalidData[0].message
        this.oldMessage = invalidData[0].message
      }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    
    
    if (changes['detectChange'] || changes['isDuplicate']) {
      if (changes.detectChange != undefined) {
        // console.log(`prev ${changes.isDuplicate.previousValue}`)
        // console.log(`prev ${changes.isDuplicate.currentValue}`)
        this.invalidMsg =""
        if (this.isDuplicate == true)  {
          this.invalidMsg = "Already Exists"
          this.currentModel.control.setErrors({ 'invalid': true })  
        } else {
          this.invalidMsg = this.oldMessage
          //this.currentModel.control.setErrors({ 'invalid': false })
          //if (this.sharedVar != undefined) {
            //this.change(this.sharedVar)
          //}
        }
      }
    }
    if (changes['sharedVar']) {
      if (this.sharedVar != undefined && this.sharedVar != '') {
        this.sharedVar = this.sharedVar[0].toUpperCase() + this.sharedVar.slice(1)
      }
      if (this.sharedVar != undefined && this.sharedVar != '' && this.isCapital == true) {
        this.sharedVar = this.sharedVar.toUpperCase()
      }
    }
  }

  keyPress(str){
    console.log(this.lengthValid)
    console.log(str.length)
    this.lengthValid = (this.maxLength == undefined || str.length < this.maxLength) ? true : false
    console.log(this.lengthValid)
    if(this.lengthValid == false){
      setTimeout(()=>{
        this.lengthValid = true
      },2000)
      return false
    }
    
  }

}
