import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-type-ahead',
  templateUrl: './type-ahead.component.html',
  styleUrls: ['./type-ahead.component.css']
})
export class TypeAheadComponent implements OnInit {

  @Input() searchresult: any = []
  @Input() sharedVar: any;
  @Input() sharedText: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;
  @Input() toolTip: string =""
  @Input() isFocus: boolean
  @Output() typeAhead = new EventEmitter()
  @Output() sharedVarChange = new EventEmitter();
  
  
  searchSelectedId: number = null;
  arrowkeyLocation: number = 0;
  displayShowSearch: boolean;
  searchbox: string;

  constructor() { }

  ngOnInit() {
    this.searchbox = this.sharedText
   // this.placeholder = "Search " + this.placeholder;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.sharedText != undefined) {
      this.searchbox = this.sharedText
      console.log(this.searchbox)
    }
    if (changes['sharedText']) {
      if (this.sharedText == '') {
        this.searchbox = ''
        console.log(this.searchbox)
      }
    }
   
    if (changes.isMandatory != undefined) {
      this.isMandatory = this.isMandatory
    }
    if(this.searchbox != undefined){
      this.searchbox = this.searchbox.charAt(0).toUpperCase() + this.searchbox.slice(1);
    }
  }

  ngAfterViewInit() {
    console.log(this.isFocus)
    if (this.isFocus == true) {
      console.log('f')
      document.getElementById(this.fieldId).focus();
    }
  }
  
  keyDown(event: KeyboardEvent) {
    if(event.keyCode !=9)
    this.displayShowSearch = true
    var activeElemnts = document.getElementsByClassName("sresult active");
    switch (event.keyCode) {
      case 38: // this is the ascii of arrow up
        if (this.arrowkeyLocation != 0) {
          this.arrowkeyLocation--;
        }
        break;
      case 40: // this is the ascii of arrow down
        if(this.arrowkeyLocation >= this.searchresult.length - 1)
          this.arrowkeyLocation = 0;
        else
          this.arrowkeyLocation++
        break;
      case 13:
        console.log(activeElemnts);
        if (activeElemnts.length > 0) {
          this.selectItem(activeElemnts[0].attributes["activeId"].nodeValue, activeElemnts[0].attributes["activeName"].nodeValue)
        }
        break;
     
    }
  }
  showSearchResults = function () {
    this.sharedVar = null;
    this.sharedVarChange.emit(null);
    if (this.searchbox && this.searchbox.length >= 1) {
      this.typeAhead.emit(this.searchbox);
    }
    else if (this.searchbox && this.searchbox.length < 1) {
      this.searchresult = []
      this.arrowkeyLocation = 0;
    }
  }
  changeStyle = function (i) {
    this.arrowkeyLocation = i;
  }

  selectItem(searchSelectedId: number, selectedSearchName: string) {
    this.displayShowSearch = false
    this.searchbox = selectedSearchName
    document.getElementById("showsearch").style.display = 'none';

    this.sharedVar = searchSelectedId;
    this.sharedVarChange.emit(searchSelectedId);
  }


}
