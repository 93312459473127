import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.css']
})
export class InputEmailComponent implements OnInit {

  constructor() { }

  @Input() sharedVar: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() invalidMsg: string
  @Input() isDisabled: boolean;
  @Input() maxLength: number
  @Input() toolTip: string= ""

  @ViewChild('modelRef') modelRef: NgModel;
  @Output() sharedVarChange = new EventEmitter();

  ngOnInit() {

  }
  change(newValue) {
    var stringLength: number = newValue.length

    if (!newValue.replace(/\s/g, '').length) {
      if (stringLength > 1) {
        this.invalidMsg = `Please enter any character`
        this.modelRef.control.setErrors({ 'invalid': true })
      }
    }
    if (newValue != null) {
      newValue = newValue.trim();
    }

    if (newValue == "") {
      if (this.isMandatory == true) {
        if (stringLength < 1) {
          this.invalidMsg = "Required"
        } else {
          this.invalidMsg = `Please enter any character`
        }
        this.modelRef.control.setErrors({ 'invalid': true })
      } else {
        this.setDefaultPatternMsg()
      }
    } else {

      if (this.maxLength != undefined) {
        if (newValue.length > this.maxLength) {
          this.invalidMsg = `Maximum Allowed Length is ${this.maxLength}`
          this.modelRef.control.setErrors({ 'invalid': true })
        } else {
          this.setDefaultPatternMsg()
        }
      }
    }
    this.sharedVar = newValue;
    this.sharedVarChange.emit(newValue);
  }

  setDefaultPatternMsg() {
    this.invalidMsg = "Input Format Should be abc@xyz.eg"
  }

}