import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, ChangeDetectorRef,AfterViewInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.css']
})
export class InputDateComponent implements OnInit, AfterViewInit {

  constructor(private cdr: ChangeDetectorRef) { }

  @Input() sharedVar: any;
  @Input() label: string;
  @Input() fieldId: string;
  @Input() isMandatory: boolean;
  @Input() max: any;
  @Input() min: any;
  @Input() isFocus: boolean
  @Input() detectInvalid: boolean;
  @Input() isDisabled: boolean;
  @Input() toolTip: string= ""

  @Output() sharedVarChange = new EventEmitter();
  @ViewChild('childRef') childRef: NgModel;

  jsonMaxDate: any
  jsonMinDate: any
  jsonSharedDate: any

  ngOnInit() {
    this.setDate()
  }
  change(newValue) {
    if (this.childRef.valid) {
      if (newValue != null) {
        this.sharedVar = newValue;

        var localDate = new Date(newValue.year, newValue.month - 1, newValue.day, 0, 0, 0, 0)

        localDate.setHours(localDate.getHours() + 5);
        localDate.setMinutes(localDate.getMinutes() + 30);
        console.log(localDate)

        this.sharedVarChange.emit(localDate);
      }
      else{
        console.log(newValue)
        this.sharedVarChange.emit(newValue)
      }
    }else{
      this.sharedVar = newValue;
      var localDate = new Date()
      localDate.setHours(localDate.getHours() + 5);
      localDate.setMinutes(localDate.getMinutes() + 30);
      console.log(localDate)
      this.sharedVarChange.emit(localDate);
    }
  }

  convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }
  ngAfterViewInit() {
    console.log(this.isFocus)
    if (this.isFocus == true) {
      document.getElementById(this.fieldId).focus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sharedVar != undefined) {
      this.setDate()
    }
    if (changes.min != undefined) {
      this.setDate()
    }
    if (changes.max != undefined) {
      this.setDate()
    }
  }
  setDate() {
    if (this.max != undefined) {

      let formattedDate = new Date(this.max)
      let day: number = formattedDate.getDate()
      let month: number = formattedDate.getMonth()
      let year: number = formattedDate.getFullYear()

      this.jsonMaxDate = { day: day, month: month + 1, year: year }
    }
    if (this.min != undefined) {

      let formattedDate = new Date(this.min)
      let day: number = formattedDate.getDate()
      let month: number = formattedDate.getMonth()
      let year: number = formattedDate.getFullYear()

      this.jsonMinDate = { day: day, month: month + 1, year: year }
      this.cdr.detectChanges();
    } else {
      this.jsonMinDate = { day: 1, month: 0, year: 1900 }
      this.cdr.detectChanges();

    }
    if (this.sharedVar != undefined) {
      let formattedDate = new Date(this.sharedVar)
      let day: number = formattedDate.getDate()
      let month: number = formattedDate.getMonth()
      let year: number = formattedDate.getFullYear()

      this.jsonSharedDate = { day: day, month: month + 1, year: year }
    }
    if(this.sharedVar == undefined)
    {
      console.log("Invalid")

    }
  }
  datepickercustom(event,d)
  {
    setTimeout(()=>{ 
      d.toggle();
 }, 100);
    
  }
   closeFix(event, datePicker) {
     if(event.target.offsetParent == null)
       datePicker.close();
      else if(event.target.offsetParent.nodeName != "NGB-DATEPICKER")
       datePicker.close();
   }
}
