import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

import { ConstantComponent } from 'app/constant.component';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';

export const TOKEN_NAME: string = 'token';

@Injectable()
export class AuthGuard implements CanActivate {
    loginStatus: any;
    constructor(private router: Router, private http1: HttpClient) { }

    canActivate() {
        if (this.isLogin()) {
            return true;
        }
        this.onTokenLoggedout();
        this.router.navigate(['/login']);
        return false;
    }

    getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
    }

    setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = localStorage.getItem('tokenExp');
        if (decoded === undefined) return null;

        let newDate = new Date(decoded);
        return newDate;
    }


    isLogin() {
        this.loginStatus = localStorage.getItem('isLoggedin');
        if (this.loginStatus == 'true') {
            return true;
        } else {
            return false;
        }
    }

    onTokenLoggedout() {
        localStorage.setItem('isLoggedout', 'true');
        let headers = new Headers({
            'Content-Type': 'application/json; charset=UTF-8',
        });
        let options = new RequestOptions({ headers: headers });

        this.http1
            .put(
                ConstantComponent.url +
                '/UserAPI/LogOut/' +
                parseInt(localStorage.getItem('userIdLogged')),
                options,
            )
            .subscribe(
                data => {
                    console.log(data);
                    if (data == true) {
                        localStorage.removeItem('isLoggedin');
                        localStorage.removeItem('userAllRights');
                        localStorage.removeItem('SuperAdminRights');
                        localStorage.removeItem('subscriberIdLogged');
                        localStorage.removeItem('usernameLogged');
                        localStorage.removeItem('subscriberNameLogged');
                        localStorage.removeItem('userIdLogged');
                        localStorage.removeItem('financialYearIdLogged');
                        localStorage.removeItem('userRights');
                        localStorage.removeItem('MaintainerRights');
                        localStorage.removeItem('tyreDashboardStatus');
                        localStorage.removeItem('companyName');
                        localStorage.removeItem('token');
                        localStorage.removeItem('tokenExp');
                    }
                },
                error => {
                },
            );
    }
}
