import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { Router } from '@angular/router'
@Component({
  selector: 'app-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.css']
})
export class ActionDropdownComponent implements OnInit {

  @Input() modulePath: string;
  @Input() itemId: number;
  @Input() isUsed: boolean;
  @Input() canEdit: boolean;
  @Input() canDelete: boolean;
  @Input() canAuthorize: boolean;
  @Input() canApprove: boolean;
  @Input() canWriteOff: boolean;
  @Input() canTerminate: boolean;
  @Input() canDispatch: boolean;
  @Input() extraActions: any;
  @Input() toolTip: string;
  @Input() canPrint: boolean;
  @Input() canPrintView: boolean;
  @Input() isActive: boolean;

  @Output() isDeleteClicked = new EventEmitter();
  @Output() openModal = new EventEmitter();
  
  customLinks : any = []

  editLink: any
  viewLink: any
  authorizeLink:any
  dispatchLink:any
  printLink:any
  writeOffLink:any
  terminateLink:any
  constructor(public CommonService:CommonService, public router:Router) { }

  ngOnInit() {
  
    this.editLink = [`/${this.modulePath}/Edit/${this.itemId}`]
    this.viewLink = [`/${this.modulePath}/Details/${this.itemId}`]
    this.authorizeLink = [`/${this.modulePath}/Authorize/${this.itemId}`]
    this.dispatchLink = [`/${this.modulePath}/Dispatch/${this.itemId}`]
    this.writeOffLink = [`/${this.modulePath}/AssetDisposalWriteOff/${this.itemId}`]
    this.printLink = [`/${this.modulePath}/Print/${this.itemId}`]
    
    if(this.extraActions != null) {
      for(var i=0; i < this.extraActions.length;i++) {

        let customLink : any
        customLink = [`/${this.modulePath}/${this.extraActions[i].path}/${this.itemId}`]

        this.customLinks.push(
          {
            path : customLink,
            text : this.extraActions[i].text,
            isFirst : this.extraActions[i].isFirst,
            iconClass : this.extraActions[i].iconClass
          }
        )
      }
    }
   
  }

  printView()
  {
    this.CommonService.print = 1;
    console.log(this.CommonService.print);
    this.router.navigate(this.viewLink);
  }

  open()
  {
    this.openModal.emit()
  }

  emitDelete() {
    this.isDeleteClicked.emit(true);
  }
  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.canDelete != undefined) {
  //     this.hideDelete = changes.hideDelete.currentValue
  //   }
  //   if (changes.hideEdit != undefined) {
  //     this.hideEdit  = changes.hideEdit.currentValue
  //   }
  // }
}
