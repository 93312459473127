import { Component } from '@angular/core';
import { retryWhen } from 'rxjs/operators';
@Component({
selector: 'app-ValidationService',
templateUrl: '../app.component.html',
styleUrls: ['../app.component.scss']
})

export class ValidationService {
   showProgress: boolean;
   currUrl: string;

 public static Pattern = {
    email: "[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$",
    PhoneNumber:"^[0-9]{12}$",
    Fax:"^[0-9]{12}$",
    Postal:"^[0-9]{6}$"
   }
 public static TextFormat = {
    // Name: "^[A-Z]{1}[a-z]+(?:[ ]+[a-zA-Z][a-z]+)*$",
    Name: "^[A-Z]{1}([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$",
    Address: "^[A-Z]{1}[a-z]+(?:[ ]+[a-zA-Z][a-z]+)*$",
    Alphabet: "^[a-zA-Z]+$",
    Number: "[0-9]+(\.[0-9][0-9]?)?",
    OnlyNumber: "^[0-9]*$"
   }
   public static ToUpper(string){
   return string.toUpperCase( )
   }
}












